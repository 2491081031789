import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class InsitunProyectService {

  private proyectos: any[] = [
    {
      nombre: 'ACCESO R1',
      year: 2019,
      autor: 'INSITUN Arquitectos',
      img: [
        {
          ruta: './assets/img/proyectos/ACCESO_R1/1.jpg',
          desc: null
        }
      ],
      type: [
        {
          icon: 'fa-cube',
          desc: 'Visualización 3D'
        },
        {
          icon: 'fa-building',
          desc: 'Ejecución de Obra'
        }
      ]
    },
    {
      nombre: 'CASA OCOQUA',
      year: 2020,
      autor: 'INSITUN Arquitectos',
      img: [
        {
          ruta: './assets/img/proyectos/CASA_OCOQUA/1.png',
          desc: null
        },
        {
          ruta: './assets/img/proyectos/CASA_OCOQUA/2.png',
          desc: null
        }
      ],
      type: [
        {
          icon: 'fa-briefcase',
          desc: 'Proyecto Ejecutivo'
        },
        {
          icon: 'fa-building',
          desc: 'Ejecución de Obra'
        }
      ]
    },
    {
      nombre: 'CASA SNB - JA',
      year: 2020,
      autor: 'INSITUN Arquitectos',
      img: [
        {
          ruta: './assets/img/proyectos/CASA_SNB-JA/1.png',
          desc: null
        },
        {
          ruta: './assets/img/proyectos/CASA_SNB-JA/2.png',
          desc: null
        }
      ],
      type: [
        {
          icon: 'fa-drafting-compass',
          desc: 'Proyecto Arquitectónico'
        },
        {
          icon: 'fa-eye',
          desc: 'Supervisión de Obra'
        }
      ]
    },
    {
      nombre: 'Departamentos "Las Trancas - STA"',
      year: 2021,
      autor: 'INSITUN Arquitectos',
      img: [
        {
          ruta: './assets/img/proyectos/DEPARTAMENTOS/1.png',
          desc: null
        },
        {
          ruta: './assets/img/proyectos/DEPARTAMENTOS/2.png',
          desc: null
        }
      ],
      type: [
        {
          icon: 'fa-briefcase',
          desc: 'Proyecto Ejecutivo'
        },
        {
          icon: 'fa-eye',
          desc: 'Supervisión de Obra'
        }
      ]
    },
    {
      nombre: 'CASA VILLEGAS',
      year: 2017,
      autor: 'INSITUN Arquitectos',
      img: [
        {
          ruta: './assets/img/proyectos/CASA_VILLEGAS/1.png',
          desc: null
        },
        {
          ruta: './assets/img/proyectos/CASA_VILLEGAS/2.png',
          desc: null
        },
        {
          ruta: './assets/img/proyectos/CASA_VILLEGAS/3.png',
          desc: null
        },
        {
          ruta: './assets/img/proyectos/CASA_VILLEGAS/4.png',
          desc: null
        }
      ],
      type: [
        {
          icon: 'fa-briefcase',
          desc: 'Proyecto Ejecutivo'
        }
      ]
    },
    {
      nombre: 'CASA LOS ZAPOTES',
      year: 2017,
      autor: 'INSITUN Arquitectos',
      img: [
        {
          ruta: './assets/img/proyectos/CASA_LOS_ZAPOTES/1.jpg',
          desc: null
        },
        {
          ruta: './assets/img/proyectos/CASA_LOS_ZAPOTES/2.jpg',
          desc: null
        },
        {
          ruta: './assets/img/proyectos/CASA_LOS_ZAPOTES/3.jpg',
          desc: null
        },
        {
          ruta: './assets/img/proyectos/CASA_LOS_ZAPOTES/4.jpg',
          desc: null
        },
        {
          ruta: './assets/img/proyectos/CASA_LOS_ZAPOTES/5.jpg',
          desc: null
        },
        {
          ruta: './assets/img/proyectos/CASA_LOS_ZAPOTES/6.jpg',
          desc: null
        },
        {
          ruta: './assets/img/proyectos/CASA_LOS_ZAPOTES/7.jpg',
          desc: null
        },
        {
          ruta: './assets/img/proyectos/CASA_LOS_ZAPOTES/8.png',
          desc: null
        }
      ],
      type: [
        {
          icon: 'fa-briefcase',
          desc: 'Proyecto Ejecutivo'
        },
        {
          icon: 'fa-eye',
          desc: 'Supervisión de Obra'
        }
      ]
    },
    {
      nombre: 'C.E.D.A.',
      year: '2018 - 2019',
      autor: 'INSITUN Arquitectos',
      img: [
        {
          ruta: './assets/img/proyectos/C.E.D.A/1.jpg',
          desc: null
        },
        {
          ruta: './assets/img/proyectos/C.E.D.A/2.jpg',
          desc: null
        },
        {
          ruta: './assets/img/proyectos/C.E.D.A/3.jpg',
          desc: null
        },
        {
          ruta: './assets/img/proyectos/C.E.D.A/4.jpg',
          desc: null
        }
      ],
      type: [
        {
          icon: 'fa-briefcase',
          desc: 'Proyecto Ejecutivo'
        },
        {
          icon: 'fa-cube',
          desc: 'Visualización 3D'
        }
      ]
    },
    {
      nombre: 'CASA TALLER SCH',
      year: '2019',
      autor: 'INSITUN Arquitectos',
      img: [
        {
          ruta: './assets/img/proyectos/CASA_TALLER_SCH/1.jpg',
          desc: null
        },
        {
          ruta: './assets/img/proyectos/CASA_TALLER_SCH/2.jpg',
          desc: null
        },
        {
          ruta: './assets/img/proyectos/CASA_TALLER_SCH/3.jpg',
          desc: null
        }
      ],
      type: [
        {
          icon: 'fa-drafting-compass',
          desc: 'Proyecto Arquitectónico'
        },
        {
          icon: 'fa-cube',
          desc: 'Visualización 3D'
        }
      ]
    },
    {
      nombre: 'CASA NATIVITAS',
      year: '2021',
      autor: 'INSITUN Arquitectos',
      img: [
        {
          ruta: './assets/img/proyectos/CASA_NATIVITAS/INT_3.png',
          desc: null
        },
        {
          ruta: './assets/img/proyectos/CASA_NATIVITAS/EXT_1.png',
          desc: null
        },
        {
          ruta: './assets/img/proyectos/CASA_NATIVITAS/EXT_2.png',
          desc: null
        },
        {
          ruta: './assets/img/proyectos/CASA_NATIVITAS/INT_1.png',
          desc: null
        },
        {
          ruta: './assets/img/proyectos/CASA_NATIVITAS/INT_2.png',
          desc: null
        }
      ],
      type: [
        {
          icon: 'fa-briefcase',
          desc: 'Proyecto Ejecutivo'
        },
        {
          icon: 'fa-cube',
          desc: 'Visualización 3D'
        }
      ]
    },
    {
      nombre: 'CASA MADERO',
      year: '2021',
      autor: 'INSITUN Arquitectos',
      img: [
        {
          ruta: './assets/img/proyectos/CASA_MADERO/SALA_COMEDOR.png',
          desc: 'SALA_COMEDOR'
        },
        {
          ruta: './assets/img/proyectos/CASA_MADERO/COCINA.png',
          desc: 'COCINA'
        },
        {
          ruta: './assets/img/proyectos/CASA_MADERO/EXTERIOR.png',
          desc: 'EXTERIOR'
        },
        {
          ruta: './assets/img/proyectos/CASA_MADERO/JARDIN.png',
          desc: 'JARDIN'
        },
        {
          ruta: './assets/img/proyectos/CASA_MADERO/SALA.png',
          desc: 'SALA'
        }
      ],
      type: [
        {
          icon: 'fa-briefcase',
          desc: 'Proyecto Ejecutivo'
        },
        {
          icon: 'fa-cube',
          desc: 'Visualización 3D'
        }
      ]
    },
    {
      nombre: 'CASA JUAREZ',
      year: '2021',
      autor: 'INSITUN Arquitectos',
      img: [
        {
          ruta: './assets/img/proyectos/CASA_JUAREZ/RENDER_5.png',
          desc: null
        },
        {
          ruta: './assets/img/proyectos/CASA_JUAREZ/RENDER_1.png',
          desc: null
        },
        {
          ruta: './assets/img/proyectos/CASA_JUAREZ/RENDER_2.png',
          desc: null
        },
        {
          ruta: './assets/img/proyectos/CASA_JUAREZ/RENDER_3.png',
          desc: null
        },
        {
          ruta: './assets/img/proyectos/CASA_JUAREZ/RENDER_4.png',
          desc: null
        }
      ],
      type: [
        {
          icon: 'fa-briefcase',
          desc: 'Proyecto Ejecutivo'
        },
        {
          icon: 'fa-cube',
          desc: 'Visualización 3D'
        }
      ]
    }
  ];

  constructor() { }

  getProyectos() {
    return this.proyectos;
  }

  getProyecto(titulo:string) {
    return this.proyectos.filter( proyecto => proyecto.nombre === titulo);
  }
}
