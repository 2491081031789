import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './view/home/home.component';
import { NavbarComponent } from './components/shared/navbar/navbar.component';
import { ScrollSpyDirective } from './directives/scroll-spy.directive';
import { FooterComponent } from './components/shared/footer/footer.component';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { ProyectComponent } from './view/proyect/proyect.component';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavbarComponent,
    ScrollSpyDirective,
    FooterComponent,
    ProyectComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxMaskModule.forRoot(),
    HttpClientModule,
    ReactiveFormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
