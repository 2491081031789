import { InsitunProyectService } from './../../services/insitun-proyect.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-proyect',
  templateUrl: './proyect.component.html',
  styleUrls: ['./proyect.component.scss']
})
export class ProyectComponent implements OnInit {

  public proyect: {};

  constructor(
    private route: ActivatedRoute,
    private _proyectService: InsitunProyectService) {
   }

  visible = false;

  public showMenu() {
    this.visible = !this.visible;
  }

  public back() {
    window.history.back();
  }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
        this.proyect = this._proyectService.getProyecto( params.nombre );
      }
    );
  }

  ngAfterViewInit() {
    window.scrollTo(0, 0);
 }

}
