import { Component, HostListener, OnInit, Renderer2, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { InsitunProyectService } from 'src/app/services/insitun-proyect.service';
import { HttpClient, HttpHeaders, HttpParams } from  "@angular/common/http";
import { Observable } from 'rxjs';
import { NgRecaptcha3Service } from 'ng-recaptcha3';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})



export class HomeComponent implements OnInit {
  public API = 'https://api.insitunarq.com/public/api/insitun/contacto';
  public proyects: {};
  public form = new FormGroup({
    name: new FormControl('', Validators.required),
    phone: new FormControl('', [Validators.required, Validators.minLength(10)]),
    email: new FormControl('', [Validators.required, Validators.email]),
    message: new FormControl('', Validators.required)
  });

  submitted = false;
  get f() { return this.form.controls; }

  customersObservable : Observable<any>;

  constructor(
    private _proyectService: InsitunProyectService,
    private http: HttpClient,
    private recaptcha3: NgRecaptcha3Service,
    private renderer: Renderer2) {
    this.proyects = this._proyectService.getProyectos();
  }
  visible = false;
  loader = false;
  success = false;
  error = false;
  currentSection = 'home';

  onSectionChange(sectionId: string) {
    this.currentSection = sectionId;
  }

  scrollTo(section) {
    this.visible = false;
    document.querySelector('#' + section)
    .scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  public getUrl()
  {
    return "url('./assets/img/BACKGROUND1.jpg')";
  }

  public getUrlHome()
  {
    return "url('./assets/img/Home.jpeg')";
  }

  public showMenu() {
    this.visible = !this.visible;
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.form.invalid) {
        return;
    }
    this.recaptcha3.getToken().then(token => {
      const formData = this.form.value;
      formData.recaptchaToken = token;
      this.loader = true;
      let headers = new HttpHeaders();
      headers = headers.set('Content-Type', 'application/json');
      this.http.post<any>(
        this.API,formData)
        .subscribe(
        data  => {
          this.loader = false;
          this.success = true;
          setTimeout(()=>{ this.success = false; }, 2000);
          this.submitted = false;
          this.form.reset();
        },
        error  => {
          this.loader = false;
          this.error = true;
          setTimeout(()=>{ this.error = false; }, 2000);
          // console.log(error);
        }

      );
    });
  }

  ngOnInit(): void {
    this.recaptcha3.init('6LeHgzgaAAAAAH6Xz0sUx-nBu6SX9viQ4NSeXgXn').then(status => {
      // status: success/error
      // success - script is loaded and greaptcha is ready
      // error - script is not loaded
      // console.log("holi",status);
    });
  }
}
