<div class="in-container">
  <div scrollSpy [spiedTags]="['DIV']" (sectionChange)="onSectionChange($event)" style="height:100vh;overflow-y: scroll;">
    <div class="portada" id="home">
      <img src="./assets/img/Home.jpeg" alt="">
      <div class="logo-container">
        <img src="./assets/img/Logo_Insitun.png" alt="" class="img-fluid">
      </div>
      <div class="arrow-down d-block d-sm-none">
        <i class="fas fa-angle-double-down fae-bounce"></i>
      </div>
    </div>
    <nav id="insutun-menu" class="navbar sticky-top d-none d-md-block">
      <div class="">
        <div class="navbar-nav">
          <a class="nav-item nav-link" [ngClass]="{'activa': currentSection==='home'}" (click)="scrollTo('home')">Inicio</a>
          <a class="nav-item nav-link" [ngClass]="{'activa': currentSection==='nosotros'}" (click)="scrollTo('nosotros')">Nosotros</a>
          <a class="nav-item nav-link" [ngClass]="{'activa': currentSection==='servicios'}" (click)="scrollTo('servicios')">Servicios</a>
          <a class="nav-item nav-link" [ngClass]="{'activa': currentSection==='proyectos'}" (click)="scrollTo('proyectos')">Proyectos</a>
          <a class="nav-item nav-link" [ngClass]="{'activa': currentSection==='contacto'}" (click)="scrollTo('contacto')">Contacto</a>
        </div>
      </div>
    </nav>
    <div class="d-block d-sm-none">
      <div class="button-menu" [ngClass]="{'bar-w': currentSection==='servicios'}" (click)="showMenu()" *ngIf="!visible">
        <i class="fas fa-bars"></i>
      </div>
      <div id="nav-right" class="nav-right" *ngIf="visible">
        <div class="close" (click)="showMenu()">
          <i class="fas fa-times"></i>
        </div>
        <div class="menu">
          <a class="nav-item nav-link" [ngClass]="{'activa': currentSection==='home'}" (click)="scrollTo('home')">Inicio</a>
          <a class="nav-item nav-link" [ngClass]="{'activa': currentSection==='nosotros'}" (click)="scrollTo('nosotros')">Nosotros</a>
          <a class="nav-item nav-link" [ngClass]="{'activa': currentSection==='servicios'}" (click)="scrollTo('servicios')">Servicios</a>
          <a class="nav-item nav-link" [ngClass]="{'activa': currentSection==='proyectos'}" (click)="scrollTo('proyectos')">Proyectos</a>
          <a class="nav-item nav-link" [ngClass]="{'activa': currentSection==='contacto'}" (click)="scrollTo('contacto')">Contacto</a>
        </div>
        <div class="redes">
          <a href="mailto:contacto@insitunarq.com" target="_blank"><i class="fas fa-envelope"></i></a>
          <a href="https://www.facebook.com/insitunarquitectos/" target="_blank"><i class="fab fa-facebook-f"></i></a>
          <a href="https://www.instagram.com/insitunarq" target="_blank"><i class="fab fa-instagram"></i></a>
        </div>
      </div>
    </div>
    <div id="nosotros">
      <section>
        <div class="separador" id="nosotros-separador" >
          <span>Nosotros</span>
        </div>
        <div class="section-nosotros" [ngStyle]="{'background-image': getUrl()}">
            <div>
              <span><span>INSITUN</span> Arquitectos</span>
              <p>
                Es una empresa que se dedica y preocupa por satisfacer las necesidades de nuestros clientes.<br>
                Somos un equipo que se mantiene a la vanguardia de la arquitectura, ofreciendo calidad, innovación, y modernidad en cada uno de nuestros proyectos.<br>
                Para nosotros "los detalles son el diseño"; tenemos en cuenta hasta el más mínimo elemento con la finalidad de que nuestros proyectos sean cómodos y confortables.
              </p>
            </div>
        </div>
      </section>
    </div>
    <div id="servicios">
      <section>
        <div class="separador servicios">
          <span>Nuestros <span>Servicios</span></span>
        </div>
        <div class="container-fluid section-servicios">
          <div class="row">
            <div class="col-6 col-sm-4 service-item">
              <i class="fas fa-vector-square"></i>
              <div>
                <p>Diseño 2D</p>
                <p>Planos arquitectónicos</p>
                <p class="yellow">Planos de instalaciones</p>
                <p>Planos en general</p>
              </div>
            </div>
            <div class="col-6 col-sm-4 service-item">
              <i class="fas fa-cube"></i>
              <div>
                <p>Diseño 3D</p>
                <p>Modelado en 3d</p>
                <p class="yellow">Render (mobiliario y acabados)</p>
                <p>Recorridos virtuales</p>
              </div>
            </div>
            <div class="col-6 col-sm-4 service-item">
              <i class="fas fa-hard-hat"></i>
              <div>
                <p>Construcción</p>
                <p>Habitacional</p>
                <p class="yellow">Industrial</p>
                <p>Comercial</p>
              </div>
            </div>
            <div class="col-6 col-sm-4 service-item">
              <i class="fas fa-project-diagram"></i>
              <div>
                <p>Supervisión</p>
                <p>Construcción</p>
                <p class="yellow">Acabados</p>
                <p>Bitácora de obra</p>
              </div>
            </div>
            <div class="col-6 col-sm-4 service-item">
              <i class="fas fa-clock"></i>
              <div>
                <p>Gestoría</p>
                <p>Tramites de Construcción</p>
                <p class="yellow">Tramites de escrituración</p>
                <p>Subcontratación y venta de predios</p>
              </div>
            </div>
            <div class="col-6 col-sm-4 service-item">
              <i class="fas fa-book"></i>
              <div>
                <p>Obra Pública</p>
                <p>Concursos de Obra</p>
                <p class="yellow">Mejoramiento de imagen urbana</p>
                <p>Obra civil en general</p>
              </div>
            </div>
            <div class="col-6 col-sm-4 service-item">
              <i class="fas fa-tools"></i>
              <div>
                <p>Remodelaciones</p>
                <p>Planos de demoliciones</p>
                <p class="yellow">Propuesta de acabados</p>
                <p>Propuesta de mobiliario</p>
              </div>
            </div>
            <div class="col-6 col-sm-4 service-item">
              <i class="fas fa-city"></i>
              <div>
                <p>Obra Civil</p>
                <p>Pavimentaciones</p>
                <p class="yellow">Líneas hidráulicas y sanitarias</p>
                <p>Obra en general</p>
              </div>
            </div>
            <div class="col-6 col-sm-4 service-item">
              <i class="fas fa-object-group"></i>
              <div>
                <p>Diseño Gráfico</p>
                <p>Láminas de presentación</p>
                <p class="yellow">Carteles promocionales</p>
                <p>Presentaciones arquitectónicas</p>
              </div>
            </div>
          </div>
        </div>

      </section>
    </div>
    <div id="proyectos">
      <section>
        <div class="separador proyectos">
          <span>Proyectos</span>
        </div>
        <div class="section-proyectos">
          <div class="eslogan">
            <span>Insitun Arquitectos, <span class="yellow">construyendo ideas</span></span>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-6 col-sm-4 col-lg-3" *ngFor="let proyecto of proyects">
                <div class="proyecto-item">
                  <div class="card">
                    <div class="box">
                      <img class="card-img-top" [src]="proyecto.img[0].ruta" alt="Card image cap">
                    </div>
                    <div class="card-body">
                      <p class="card-text">{{proyecto.nombre}}</p>
                      <p><span class="yellow">INSITUN</span> Arquitectos</p>
                      <div class="footer">
                        <p>{{proyecto.year}}</p>
                        <div class="icons">
                          <i class="fas {{icon.icon}}" *ngFor=" let icon of proyecto.type"></i>
                        </div>
                      </div>
                    </div>
                    <a [routerLink]="['/proyect']" [queryParams]="{ nombre: proyecto.nombre}">
                      <div class="hover">
                        <i class="fas fa-info-circle"></i>
                        <span>Ver mas</span>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div id="contacto">
      <section>
        <div class="contacto">
          <div class="eslogan">
            <span>Si tienes preguntas, no dudes en contactarnos.</span>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-12 col-sm-6">
                <form class="text-center form-insitun" [formGroup]="form" (ngSubmit)="onSubmit()" >
                  <div class="form-group">
                    <input
                      type="text"
                      autocomplete="off"
                      class="form-control input-in"
                      placeholder="Nombre"
                      formControlName="name"
                      id="name"
                      required
                      [ngClass]="{ 'is-invalid': submitted && f.name.errors }"
                      >
                      <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                          <div *ngIf="f.name.errors.required">El nombre es requerido.</div>
                      </div>
                  </div>
                  <div class="form-group">
                    <input
                      type="phone"
                      autocomplete="off"
                      class="form-control input-in"
                      placeholder="Teléfono"
                      mask="(000) 000-0000"
                      formControlName="phone"
                      required
                      [ngClass]="{ 'is-invalid': submitted && f.phone.errors }"
                      >
                      <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                          <div *ngIf="f.phone.errors.required">El teléfono es requerido.</div>
                          <div *ngIf="f.phone.errors.minlength">El teléfono no es valido.</div>
                      </div>
                  </div>
                  <div class="form-group">
                    <input
                      type="email"
                      autocomplete="off"
                      class="form-control input-in"
                      aria-describedby="emailHelp"
                      placeholder="Correo"
                      formControlName="email"
                      required
                      [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                      >
                      <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                          <div *ngIf="f.email.errors.required">El correo es requerido.</div>
                          <div *ngIf="f.email.errors.email">Ingresa un correo valido.</div>
                      </div>
                  </div>
                  <div class="form-group">
                    <textarea
                      class="form-control textarea"
                      rows="3"
                      placeholder="Mensaje..."
                      formControlName="message"
                      required
                      [ngClass]="{ 'is-invalid': submitted && f.message.errors }"
                      ></textarea>
                      <div *ngIf="submitted && f.message.errors" class="invalid-feedback">
                          <div *ngIf="f.message.errors.required">Por favor dejanos un mensaje.</div>
                      </div>
                  </div>
                  <div class="loading" *ngIf="loader">
                    <i class="fas fa-spinner fa-spin"></i>
                  </div>
                  <div class="mensaje-success" *ngIf="success">
                    <p>¡Mensaje enviado!</p>
                  </div>
                  <div class="mensaje-error" *ngIf="error">
                    <p>¡Oops! Lo siento hubo un error.</p>
                  </div>
                  <!-- <div class="form-check">
                    <input type="checkbox" class="form-check-input" id="terms">
                    <label class="form-check-label" for="terms">Acepto <a href="#" class="term">Terminos y condiciones</a></label>
                  </div> -->
                  <button type="submit" class="btn btn-outline-warning" *ngIf="!loader">Enviar</button>
                </form>
              </div>
              <div class="col-12 col-sm-6 container-redes">
                <div class="redes">
                  <div>
                    <div class="icon"><i class="fas fa-envelope"></i></div>
                    <div class="desc"><a href="mailto:contacto@insitunarq.com" target="_blank">contacto@insitunarq.com</a></div>
                  </div>
                  <div>
                    <div class="icon"><i class="fab fa-facebook-f"></i></div>
                    <div class="desc"><a href="https://www.facebook.com/insitunarquitectos/" target="_blank">Facebook/insitunarquitectos</a></div>
                  </div>
                  <div>
                    <div class="icon"><i class="fab fa-instagram"></i></div>
                    <div class="desc"><a href="https://www.instagram.com/insitunarq" target="_blank">Instagram/@insitunarq</a></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <app-footer></app-footer>
      </section>
    </div>
  </div>
</div>



