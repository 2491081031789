
<div class="bar-navigation d-block d-sm-none">
  <a class="arrow-back-xs" (click)="back()"><i class="fas fa-arrow-circle-left"></i></a>
  <div class="button-menu" (click)="showMenu()">
    <i class="fas fa-bars"></i>
  </div>
</div>
<div class="d-block d-sm-none">
  <div id="nav-right" class="nav-right" *ngIf="visible">
    <div class="close" (click)="showMenu()">
      <i class="fas fa-times"></i>
    </div>
    <div class="menu">
      <a class="nav-item nav-link active" href="/home#home">Inicio</a>
      <a class="nav-item nav-link" href="/home#nosotros">Nosotros</a>
      <a class="nav-item nav-link" href="/home#servicios">Servicios</a>
      <a class="nav-item nav-link" href="/home#proyectos">Proyectos</a>
      <a class="nav-item nav-link" href="/home#contacto">Contacto</a>
    </div>
    <div class="redes">
      <a href="mailto:contacto@insitunarq.com" target="_blank"><i class="fas fa-envelope"></i></a>
      <a href="https://www.facebook.com/insitunarquitectos/" target="_blank"><i class="fab fa-facebook-f"></i></a>
      <a href="https://www.instagram.com/insitunarq" target="_blank"><i class="fab fa-instagram"></i></a>
    </div>
  </div>
</div>
<div id="carouselProyect" class="carousel slide" data-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item" [ngClass]="{'active':i === 0}" *ngFor="let galery of proyect[0].img; let i = index">
      <img class="d-block" [src]="galery.ruta" alt="First slide">
    </div>
  </div>
  <a *ngIf="proyect[0].img.length > 1" class="carousel-control-prev" data-target="#carouselProyect" role="button" data-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a *ngIf="proyect[0].img.length > 1" class="carousel-control-next" data-target="#carouselProyect" role="button" data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
  <a class="arrow-back d-none d-md-block" (click)="back()"><i class="fas fa-arrow-circle-left"></i></a>
</div>
<div class="descripcion">
  <div class="row">
    <div class="col-sm-9 col-xs-12">
      <p class="name">{{proyect[0].nombre}}</p>
      <p class="by"><span class="yellow">INSITUN</span> Arquitectos</p>
      <p class="year">{{proyect[0].year}}</p>
    </div>
    <div class="col-sm-3 col-xs-12">
      <div class="container-clases">
        <div class="clase col-xs-6" *ngFor="let types of proyect[0].type">
          <div class="icon"><i class="fas {{types.icon}}"></i></div>
          <div class="description"><p>{{types.desc}}</p></div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="footer">
  <div class="container-redes d-none d-md-block">
    <div class="redes">
      <div>
        <div class="icon"><i class="fas fa-envelope"></i></div>
        <div class="desc"><a href="mailto:contacto@insitunarq.com" target="_blank">contacto@insitunarq.com</a></div>
      </div>
      <div>
        <div class="icon"><i class="fab fa-facebook-f"></i></div>
        <div class="desc"><a href="https://www.facebook.com/insitunarquitectos/" target="_blank">Facebook/insitunarquitectos</a></div>
      </div>
      <div>
        <div class="icon"><i class="fab fa-instagram"></i></div>
        <div class="desc"><a href="https://www.instagram.com/insitunarq" target="_blank">Instagram/@insitunarq</a></div>
      </div>
    </div>
  </div>
  <div class="redes-xs d-block d-sm-none">
    <a href="mailto:contacto@insitunarq.com" target="_blank"><i class="fas fa-envelope"></i></a>
    <a href="https://www.facebook.com/insitunarquitectos/" target="_blank"><i class="fab fa-facebook-f"></i></a>
    <a href="https://www.instagram.com/insitunarq" target="_blank"><i class="fab fa-instagram"></i></a>
  </div>
  <app-footer></app-footer>
</div>

